import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'shouye',
    component: () => import('../views/ShouYe.vue')
  },
  {
    path: '/jieshao',
    name: 'jieshao',
    component: () => import('../views/JieShao.vue')
  },
  {
    path: '/fuwu',
    name: 'fuwu',
    component: () => import('../views/FuWu.vue')
  },
  {
    path: '/fagui',
    name: 'fagui',
    component: () => import('../views/FaGui.vue')
  },
  {
    path: '/jieshao',
    name: 'jieshao',
    component: () => import('../views/JieShao.vue')
  },
  {
    path: '/zixun',
    name: 'zixun',
    component: () => import('../views/ZiXun.vue')
  },
  {
    path: '/dayi',
    name: 'dayi',
    component: () => import('../views/DaYi.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
