<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>

</style>
<script>

    export default{
        components:{}
    }
</script>